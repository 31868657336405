import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0,3,4];

export const dictionary = {
		"/(root)": [~5,[2]],
		"/(root)/(app)/admin": [~6,[2,3]],
		"/(root)/checklists/[uuid]": [~11,[2]],
		"/(root)/(app)/dashboard": [7,[2,3]],
		"/(root)/login": [~12,[2]],
		"/(root)/(app)/new": [8,[2,3]],
		"/(root)/(app)/settings": [9,[2,3]],
		"/(root)/(app)/test/checklists": [10,[2,3]],
		"/(root)/t/[id=int]": [~13,[2,4]],
		"/(root)/t/[id=int]/events": [~14,[2,4]],
		"/(root)/t/[id=int]/events/[event=int]": [~15,[2,4]],
		"/(root)/t/[id=int]/members": [~16,[2,4]],
		"/(root)/t/[id=int]/results": [~17,[2,4]],
		"/(root)/t/[id=int]/results/histo/[event]/render": [~18],
		"/(root)/t/[id=int]/teams": [~19,[2,4]],
		"/(root)/t/[id=int]/tracks": [~20,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';